import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import img1 from "../Components/Images/bg.jpg";

function Goals() {
  return (
    <Box p={4}  >
      <Text textAlign="center" fontSize="43px" fontWeight="bold" fontFamily="Arial, sans-serif">
       Goals
      </Text>
      <Flex
        align="center"
        justifyContent="center"
        gap={4}
        wrap="wrap" // Allow wrapping of boxes on smaller screens
        p={4}
      >
        <Box
          backgroundImage={`url(${img1})`}
          backgroundSize="cover"
          backgroundPosition="center"
          w="30rem"
          h="20rem"
          d="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={4} // Added padding inside the Box
        >
          <Text textAlign="center" fontSize="32px" fontWeight="bold" fontFamily="Helvetica, Arial, sans-serif" color="gray">
            Mission
          </Text>
          <br/>
          <Text textAlign="center" fontSize={{ base: "20px", md: "28px", lg: "22px" }} fontFamily="Helvetica, Arial, sans-serif" color="black">
            Our Mission is to be India's Largest Service Providing Company in the Field of Transportation and Connecting Customers and Drivers Across Globe.
          </Text>
        </Box>
        
        <Box
          backgroundImage={`url(${img1})`}
          backgroundSize="cover"
          backgroundPosition="center"
          w="30rem"
          h="20rem"
          d="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={4} // Added padding inside the Box
        >
          <Text textAlign="center" fontSize="32px" fontWeight="bold" fontFamily="Helvetica, Arial, sans-serif" color="gray">
            Vision
          </Text>
          <br/>
          <Text textAlign="center" fontSize={{ base: "20px", md: "28px", lg: "22px" }} fontFamily="Helvetica, Arial, sans-serif" color="black">
            Our Vision is to Give Opportunity to Earn the Good Source of Income to our Drivers and Riders and Helping them and our Customers to Create Better Future Together.
          </Text>
        </Box>
        
        <Box
          backgroundImage={`url(${img1})`}
          backgroundSize="cover"
          backgroundPosition="center"
          w="30rem"
          h="20rem"
          d="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={4} // Added padding inside the Box
        >
          <Text textAlign="center" fontSize="32px" fontWeight="bold" fontFamily="Helvetica, Arial, sans-serif" color="gray">
            Values
          </Text>
          <br/>
          <Box textAlign="center" fontSize={{ base: "20px", md: "28px", lg: "22px" }} fontFamily="Helvetica, Arial, sans-serif" color="black">
          <ul style={{ padding: 0, listStyleType: 'none' }}> {/* Remove default list styles */}

              <li textAlign="center">Trustworthy</li>
              <li textAlign="center">Be Honest and On Time</li>
              <li textAlign="center">Be Humble & Polite</li>
              <li textAlign="center">Customer is God</li>
            </ul>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
export default Goals;
