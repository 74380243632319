import React from 'react';
import { Box, Heading, Text, Stack, List, ListItem } from '@chakra-ui/react';

const Privacypolicy = () => {
  return (
    <Box 
    p={{ base: 4, md: 8 }} 
    display="flex" 
    justifyContent="center" 
    alignItems="center" 
    w="70vw" 
    mx="auto"
  >
    <Stack spacing={6} w="100%">
      <Heading as="h1" size="2xl" textAlign="center">
        Privacy Policy
      </Heading>
      <Text fontSize="23px">
        RIDO India Inc. ("we", "our", "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, 
        use, and share information about you when you use our mobile application ("App") and services (collectively, "Services").
      </Text>

      <Heading as="h2" size="xl" mt={8}>
        Information We Collect
      </Heading>
      <Text fontSize="md">
        Personal Information: When you register or use our Services, we collect information such as your name, email address,
        phone number, and payment information.
      </Text>

      <Heading as="h3" size="lg" mt={6}>
        How We Use Your Information
      </Heading>
      <Text fontSize="md">
        To Provide Services: We use your personal and location data to connect you with drivers, process payments, and offer customer support.
        Improving Services: Analyzing usage patterns to enhance our App's functionality and user experience.
      </Text>

      <Heading as="h3" size="lg" mt={6}>
        Sharing Your Information
      </Heading>
      <Text fontSize="md">
        With Drivers: Sharing your location and contact information with drivers to fulfill ride requests.
        Service Providers: Third-party vendors who assist us with payment processing, data analysis, and marketing.
        Legal Requirements: Disclosing information if required by law or to protect our rights.
      </Text>

      <Heading as="h3" size="lg" mt={6}>
        Data Security
      </Heading>
      <Text fontSize="md">
        We implement appropriate security measures to protect your data. However, 
        no internet-based service is completely secure, and we cannot guarantee absolute security.
      </Text>

      <Heading as="h3" size="lg" mt={6}>
        Your Choices
      </Heading>
      <Text fontSize="md">
        Access and Update: You can access and update your personal information through the App.
        Location Data: You can control location tracking through your device settings.
        Opt-Out: You may opt-out of receiving promotional communications from us.
      </Text>

      <Heading as="h3" size="lg" mt={6}>
        Children's Privacy
      </Heading>
      <Text fontSize="md">
        Our Services are not intended for children under 13.
        We do not knowingly collect information from children under 13.
      </Text>

      <Heading as="h3" size="lg" mt={6}>
        Changes to This Policy
      </Heading>
      <Text fontSize="md">
        We may update this Privacy Policy from time to time. 
        We will notify you of any changes by posting the new policy on our App.
      </Text>

      <Heading as="h3" size="lg" mt={6}>
        Contact Us
      </Heading>
      <Text fontSize="md">
        If you have any questions about this Privacy Policy, please contact us at:
      </Text>
      <List spacing={2} mt={4}>
        <ListItem>Email: support@example.com</ListItem>
        <ListItem>Phone: (123) 456-7890</ListItem>
        <ListItem>Address: 1234 Street Name, City, State, ZIP</ListItem>
      </List>
    </Stack>
  </Box>
  );
};

export default Privacypolicy;
