import { Box } from "@chakra-ui/react";
import React from "react";
import Home from "../Components/Home";
import About from "../Components/About";
import Goals from "../Components/Goals";

const Homepage = () => {
    return (
      <Box>
        <Home />
        <About/>
        <Goals/>
       
       
       
      </Box>
    );
  };
  
  export default Homepage;