import React from "react";
import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";
import img1 from "../Components/Images/rider.jpg"

function AboutUs() {
  return (
    <Box bg="rgba(0, 0, 0, 0.9)" height="auto" color="white">
      <Flex direction={{ base: "column", md: "row" }} align="center" justify="center" p={8}>
        <Box textAlign="left" maxW="600px">
          <Text fontSize="4xl" fontWeight="bold" mb={4}>
            About Us
          </Text>
          <Text fontSize="xl" mb={8}>
          RIDO India Inc. is a company which is providing bike and car rental services and having approx 1200 plus riders and drivers across India.<br/>

We have started from Bangalore Karnataka and now having offices and services in complete South India and moving ahead to North areas soon. It's a fastest growing Indian multinational company. Have tie ups with leading brands and huge MNC across globe.


          </Text>
        </Box>
        <Box mt={{ base: 8, md: 0 }} ml={{ md: 8 }} >
          <Image src={img1} alt="Company Image" w={{base:"100vw" , sm:"30vw" , md:"30vw" , lg:"30vw" , xl:"30vw"}} borderRadius={'25px'} /> {/* Replace with valid image path */}
        </Box>
      </Flex>
      
      <Flex direction="column" align="center" p={8}>
        <Text fontSize="55px" fontWeight="bold" mb={4}>
          Our Goals
      </Text>
        <Box textAlign="left" maxW="800px" mt={16}>
          <Text fontSize="3xl" fontWeight="bold" mb={4}>
            RESEARCH
          </Text>
          <Text fontSize="xl" mb={8}>
            Have Huge IT team more than 200 Software Engineers Working on Application to Connect Customers With Drivers & Riders
          </Text>

          <Text fontSize="3xl" fontWeight="bold" mb={4}>
            PRACTICE
          </Text>
          <Text fontSize="xl" mb={8}>
            Giving more Than Guaranteed 30 Rides and Drivers to Our Running Captains to Earn Good Income on Regular Basis
          </Text>

          <Text fontSize="3xl" fontWeight="bold" mb={4}>
            IMPROVEMENT
          </Text>
          <Text fontSize="xl" mb={8}>
            Learning from Other Groups and Solving Issues on Regular Basis
            Always take Review of Customer and Solve any Issue within Short Notice
          </Text>      
        </Box>
      </Flex>
    </Box>
  );
}

export default AboutUs;
