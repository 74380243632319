import React from "react";
import Navbar from "./Components/Navbar"; // Ensure the path is correct
import Homepage from "./Pages/Homepage"; // Ensure the path is correct
import { Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer"; // Ensure the path is correct
import AboutUs from "../src/Components/AboutUs";
import Privacypolicy from "../src/Components/Privacypolicy";
import  Term from "../src/Components/Term";
import Services from "../src/Components/Services";
import ContactUs from "./Components/ContactUs";
import Toggle from "../src/Components/Toggle";

function App() {
  return (
    <React.Fragment>
      <Navbar />
    
     <Toggle/>
  
      <Routes>
      <Route path="/" element={<Homepage />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Privacypolicy" element={<Privacypolicy/>} />
        <Route path="/Term" element={<Term/>} />
        <Route path="/Services" element={<Services/>} />
        <Route path="/ContactUs" element={<ContactUs/>} />
      </Routes>
      <Footer /> {/* Correctly close the Footer component */}
    </React.Fragment>
  );
}

export default App;
