import React from "react";
import { Box, Flex, Text, Button, Image , Stack,
  Icon,
  VStack,
  HStack, } from "@chakra-ui/react";
import img1 from "../Components/Images/Bike.png";
import { FaRegHandshake, FaMotorcycle, FaMobileAlt } from "react-icons/fa";
import riderImg from "../Components/Images/Professinal.png"; // Correct path to the image
import AffordableImg from "../Components/Images/Affordable.png"; // Correct path to the image
import EasyImg from "../Components/Images/easy.png"; // Correct path to the image
import img3 from "../Components/Images/WEbsite.jpg";
import img2 from "../Components/Images/up.png";
import img4 from "../Components/Images/Logo.png"

function Home() {
  return (
    <Box>      
      <Box
        bgImage={`url(${img3})`}
        bgPosition="center"
        bgSize="cover"
        height={{ base: "60vh", md: "33vh" , lg:"65vh" , xl:"65vh" }}
        width={{md:"100%"}}
        color="white"
        display={{base:"none" , sm:"none", md:"flex" , lg:"flex" , xl:"flex" }}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        
      </Box>
      {/* Features Section */}
      <Box py={16} px={8}  >
      <Text
        fontSize="4xl"
        fontWeight="bold"
        textAlign="center"
        mb={12}
        color="black"
      >
        Why Choose RIDO?
      </Text>
      <Flex
        direction={{ base: "column", sm:"column" ,md: "column"  , lg:"row" , xl:"row"}}
        justify="space-between"
       margin={'auto'}
        wrap="wrap"
       
      >
        <FeatureCard 
        
          icon={FaMotorcycle}
          title="Affordable Rides"
          description="Experience the most cost-effective rides in town with RIDO."
          img={AffordableImg} // Replace with actual image path or URL
        />
        <FeatureCard
          icon={FaRegHandshake}
          title="Professional Drivers"
          description="Our drivers are trained to provide you with a safe and comfortable ride."
          img={riderImg} // Replace with actual image path or URL
        />
        <FeatureCard
          icon={FaMobileAlt}
          title="Easy Booking"
          width={'48%'}
          description="Book your ride in just a few taps with our user-friendly app."
          img={img2} // Replace with actual image path or URL
        />
      </Flex>
    </Box>     
      <Box bg="black" py={16} color="white">
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="space-between"
        maxW="1200px"
        mx="auto"
        px={8}
      >
        <VStack align="start" spacing={4}>
          <Text fontSize="3xl" fontWeight="bold">
            Ready to Ride with RIDO?
          </Text>
          <Text fontSize="xl">
            Download our app now and get started with the best ride-hailing service.
          </Text>
          <Button
            size="lg"
            colorScheme="gray"    
            bg="white"
            color="black"
            _hover={{ bg: "gray.200" }}
          >
            Download App
          </Button>
        </VStack>
        <Image
          src={img4}
          alt="RIDO App Splash"
          boxSize={{ base: "200px", md: "230px" }}
          mt={{ base: 8, md: 0 }}
        />
      </Flex>
    </Box>
    </Box>
  );
}

const FeatureCard = ({ icon, title, description, img }) => {
  return (
    <Box
    margin={'auto'}
      maxW={{ base: "100%", md: "100%" , lg:"30%" }}
      mb={{ base: 8, md: 0 }}
      bg="white"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="none"
      transition="transform 0.3s"
      _hover={{ transform: "scale(1.05)" }}
    >
      <Image src={img} alt={title} boxSize="100%" objectFit="cover" />
      <Box p={6}>
        <Icon as={icon} w={10} h={10} color="teal.500" />
        <Text fontSize="xl" fontWeight="bold" mt={4}>
          {title}
        </Text>
        <Text mt={2} fontSize="md" color="gray.600">
          {description}
        </Text>
      </Box>
    </Box>
  );
}
export default Home;