import React from 'react';
import { Box, Heading, Text, Stack, List, ListItem } from '@chakra-ui/react';

const TermsAndConditions = () => {
  return (
    <Box 
      p={{ base: 4, md: 8 }} 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      w="70vw" 
      mx="auto"
    >
      <Stack spacing={6} w="100%">
        <Heading as="h1" size="2xl" textAlign="center">
          Terms and Conditions
        </Heading>
        <Text fontSize="lg">
        Welcome to RIDO India Inc. ("RIDO"). By using our mobile application ("App") and services (collectively, "Services"), you agree to these Terms and Conditions.
        </Text>

        <Heading as="h2" size="lg" mt={8}>
        1. Use of Services
        </Heading>
        <Text fontSize="md">
        Eligibility: You must be at least 18 years old to use our Services.
Account Registration: You must provide accurate information and keep your account information updated.
User Conduct: You agree to use the Services for lawful purposes and not to engage in any prohibited activities.        </Text>

        <Heading as="h3" size="lg" mt={6}>
      2.  Payment        </Heading>
        <Text fontSize="md">
        Fares and Charges: You agree to pay all applicable fares and charges for rides booked through our App.
Payment Methods: We accept various payment methods, including credit/debit cards and digital wallets.
Refunds: Refunds are subject to our cancellation policy and are processed at our discretion.
        </Text>

        <Heading as="h3" size="lg" mt={6}>
        3. Ride Requests
        </Heading>
        <Text fontSize="md">
        Booking: You can book a ride through our App by specifying your pickup and drop-off locations.
Cancellations: You may cancel a ride request within a specified time frame. Cancellations after this period may incur a fee.
Driver Availability: Ride availability is subject to driver availability in your area.
        </Text>

        <Heading as="h3" size="lg" mt={6}>
        4. User Responsibilities
        </Heading>
        <Text fontSize="md">
        Accurate Information: You must provide accurate ride details and contact information.
Conduct: You agree to behave respectfully towards drivers and comply with their instructions.
Safety: You must wear a seatbelt and follow all applicable traffic laws.
        </Text>

        <Heading as="h3" size="lg" mt={6}>
        5. Limitation of Liability
        </Heading>
        <Text fontSize="md">
        Service Interruptions: We are not responsible for any interruptions or errors in the Services.
Third-Party Actions: We are not liable for the actions or conduct of drivers or other users.
Disclaimer: Our Services are provided "as is" without warranties of any kind.
        </Text>

        <Heading as="h3" size="lg" mt={6}>
        6. Termination
        </Heading>
        <Text fontSize="md">
        We reserve the right to suspend or terminate your access to our Services at our discretion, without notice.
        </Text>

        <Heading as="h3" size="lg" mt={6}>
        7. Changes to Terms
        </Heading>
        <Text fontSize="md">
        We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new terms on our App.
        </Text>
        <Heading as="h3" size="lg" mt={6}>
        8. Governing Law
        </Heading>
        <Text fontSize="md">
        These Terms and Conditions are governed by the laws of [Your Country/State], without regard to its conflict of law principles.
        </Text>
       
      </Stack>
    </Box>
  );
};

export default TermsAndConditions;
