import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
  Stack,
  useToast
} from "@chakra-ui/react";

function ContactUs() {
  const toast = useToast();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you would typically handle form submission, e.g., send data to an API

    toast({
      title: "Message Sent",
      description: "We have received your message and will get back to you shortly.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box bg="rgba(0, 0, 0, 0.9)" color="white" p={8} minH="100vh">
      <Heading as="h1" size="2xl" mb={8} textAlign="center">
        Contact Us
      </Heading>
      <Flex direction={{ base: "column", md: "row" }} justify="center">
        <Box flex="1" maxW="600px" mx="auto">
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Input
                placeholder="Your Name"
                variant="outline"
                size="lg"
                required
              />
              <Input
                type="email"
                placeholder="Your Email"
                variant="outline"
                size="lg"
                required
              />
              <Input
                placeholder="Subject"
                variant="outline"
                size="lg"
                required
              />
              <Textarea
                placeholder="Your Message"
                variant="outline"
                size="lg"
                rows={6}
                required
              />
              <Button
                type="submit"
                colorScheme="teal"
                size="lg"
                mt={4}
              >
                Send Message
              </Button>
            </Stack>
          </form>
        </Box>
      </Flex>
      <Box mt={16} textAlign="center">
        <Heading as="h2" size="xl" mb={4}>
          Our Contact Details
        </Heading>
        <Text fontSize="lg" mb={2}>
          📞 Phone: +123 456 7890
        </Text>
        <Text fontSize="lg" mb={2}>
          📧 Email: ridoindiainc@gmail.com
        </Text>
        <Text fontSize="lg" mb={2}>
          📍 Address:  Logix Park 
 Tower C , 9th Floor 
Noida Sector 62 
Uttar Pradesh
        </Text>
      </Box>
    </Box>
  );
}

export default ContactUs;
