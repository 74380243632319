import React, { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbLink,
  Spacer,
  Image,
  Button,
  Box,
  Flex,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  IconButton,
  Text
} from "@chakra-ui/react";
import { ChevronRightIcon, HamburgerIcon } from "@chakra-ui/icons";
import img1 from './Images/Logo.png';
import { useNavigate } from "react-router-dom";

function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <Box
        bgColor="white"
        p="18px"
        direction={{ base: "column", md: "row" }}
        display={{ base: 'none', lg: 'none' }}
        w="100%"
        h="100px" // Set a fixed height for the navbar
        boxShadow="md" // Optional: adds shadow for visual separation
      >
        <Flex alignItems="center" h="100%" w="100%">
          <Spacer />
          <Image
            boxSize="70px"
            src={img1}
            alt="Logo"
            cursor="pointer"
            onClick={() => navigate("/")}
            marginLeft={'-8%'}
            width={'6%'}
            height={'auto'} // Adjusted to maintain aspect ratio
          />
          <Spacer />
          <Breadcrumb separator={<ChevronRightIcon color="gray.500" />} spacing="12px" flex="1">
            <BreadcrumbLink
              fontWeight="bold"
              color="black"
              fontSize="2rem"
              fontFamily="Calibri"
              onClick={() => navigate("/AboutUs")}
            >
              About Us
            </BreadcrumbLink>
            <Spacer />
            <BreadcrumbLink
              fontWeight="bold"
              color="black"
              fontSize="2rem"
              fontFamily="Calibri"
              onClick={() => navigate("/Services")}
            >
              Services
            </BreadcrumbLink>
            <Spacer />
            <BreadcrumbLink
              fontWeight="bold"
              color="black"
              fontSize="2rem"
              fontFamily="Calibri"
              onClick={() => navigate("/ContactUs")}
            >
              Contact
            </BreadcrumbLink>
          </Breadcrumb>
          <Button
            bg="black"
            fontSize="23px"
            ml="32px"
            fontFamily="Calibri"
            onClick={() => navigate("/BookARide")}
            color={'white'}
            _hover={{ bg: 'gray.700' }}
          >
            Book a Ride
          </Button>
          <Spacer />
        </Flex>
      </Box>

      <Box
        display={{ base: 'flex', lg: 'none' }}
        p="4"
        bgColor="white"
        boxShadow="md"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconButton
          icon={<HamburgerIcon />}
          onClick={onOpen}
          aria-label="Open Menu"
        />
        <Image
          boxSize="50px"
          src={img1}
          alt="Logo"
          cursor="pointer"
          onClick={() => navigate("/")}
        />
      </Box>

      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image
              boxSize="70px"
              src={img1}
              alt="Logo"
              cursor="pointer"
              onClick={() => navigate("/")}
            />
          </DrawerHeader>
          <DrawerBody>
            <Flex direction="column" mt={8}>
              <Text
                fontWeight="bold"
                fontSize="xl"
                mb={4}
                cursor="pointer"
                onClick={() => {
                  navigate("/AboutUs");
                  onClose();
                }}
              >
                About Us
              </Text>
              <Text
                fontWeight="bold"
                fontSize="xl"
                mb={4}
                cursor="pointer"
                onClick={() => {
                  navigate("/Services");
                  onClose();
                }}
              >
                Services
              </Text>
              <Text
                fontWeight="bold"
                fontSize="xl"
                mb={4}
                cursor="pointer"
                onClick={() => {
                  navigate("/ContactUs");
                  onClose();
                }}
              >
                Contact
              </Text>
              <Button
                bg="black"
                fontSize="lg"
                fontFamily="Calibri"
                onClick={() => {
                  navigate("/BookARide");
                  onClose();
                }}
                color={'white'}
                _hover={{ bg: 'gray.700' }}
                mt={4}
              >
                Book a Ride
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navbar;
