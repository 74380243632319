import React from "react";
import { Box, Flex, Text, Input, Button, Image, Spacer } from "@chakra-ui/react";
import img1 from "../Components/Images/Delivery.png"

function About() {
  return (
    <Box bg="white" height={{base:"90vh" , sm:"90vh" ,md:"95vh" , lg:"70vh" , xl:"88vh" }}>
      <Flex direction={{ base: "column", sm:"column" , md: "column" , lg:"row"  , xl:"row" }} align="center" justify="center" p={8}>
        
        <Box ml={'16%'} >
          <Image src={img1} alt="Company Image"   />
        </Box>
        <Spacer/>
        <Box textAlign="left" maxW="600px">
          <Text fontSize="4xl" fontWeight="bold" mb={4}>
            Project Overview
          </Text>
         
          <Text fontSize="xl" mb={8}>
          RIDO India Inc. is a multinational company started in 2014 at Bangalore, India. 
          Leading Startup of India award was given to the company. Now we have presence across India.          </Text>
                 </Box>
      </Flex>
      
     
    </Box>
  );
}

export default About;
