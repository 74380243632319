import React from "react";
import {
  Breadcrumb,
  BreadcrumbLink,
  Spacer,
  Image,
  Button,
  Box,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import img1 from './Images/Logo.png';
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  
  // Adjust font size and button size based on the screen size
  const fontSize = useBreakpointValue({ base: "0.8rem", md: "1rem", xl: "1.5rem" });
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <Box
      bgColor="white"
      p={{ base: "12px", md: "18px" }} // Adjust padding for different screen sizes
      direction={{ base: "column", md: "row" }}
      display={{ base: 'none', lg: 'flex' }}
      w="100%"
      h={{ base: "80px", md: "100px" }} // Adjust height for different screen sizes
      boxShadow="md"
    >
      <Flex alignItems="center" h="100%" w="100%">
        <Spacer />
        <Image
          boxSize={{ base: "50px", md: "70px" }} // Adjust logo size for different screen sizes
          src={img1}
          alt="Logo"
          cursor="pointer"
          onClick={() => navigate("/")}
          ml={{ base: '-6%', md: '-8%' }}
          w={{ base: '8%', md: '6%' }} // Adjust width for different screen sizes
          height={'auto'} // Maintain aspect ratio
        />
        <Spacer />
        <Breadcrumb
          separator={<ChevronRightIcon color="gray.500" />}
          spacing="12px"
          flex="1"
        >
          <BreadcrumbLink 
            fontWeight="bold"
            color="black"
            fontSize={fontSize} // Use responsive font size
            fontFamily="Calibri"
            onClick={() => navigate("/AboutUs")}
          >
            About Us
          </BreadcrumbLink>
          <Spacer />
          <BreadcrumbLink
            fontWeight="bold"
            color="black"
            fontSize={fontSize} // Use responsive font size
            fontFamily="Calibri"
            onClick={() => navigate("/Services")}
          >
            Services
          </BreadcrumbLink>
          <Spacer />
          <BreadcrumbLink
            fontWeight="bold"
            color="black"
            fontSize={fontSize} // Use responsive font size
            fontFamily="Calibri"
            onClick={() => navigate("/Contactus")}
          >
            Contact
          </BreadcrumbLink>
        </Breadcrumb>
        <Button
          bg="black"
          fontSize={fontSize} // Use responsive font size
          size={buttonSize} // Use responsive button size
          ml="32px"
          fontFamily="Calibri"
          onClick={() => navigate("/BookARide")}
          color={'white'}
          _hover={{ bg: 'gray.700' }}
        >
          Book a Ride
        </Button>
        <Spacer />
      </Flex>
    </Box>
  );
}
export default Navbar;