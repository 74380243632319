import React from "react";
import { Box, Flex, Text, Stack, Icon, Heading } from "@chakra-ui/react";
import { FaMotorcycle, FaPhone, FaDollarSign, FaMapMarkerAlt } from "react-icons/fa"; // Example icons, you can use others

const servicesData = [
  {
    icon: FaMotorcycle,
    title: "Reliable Rides",
    description: "Enjoy safe and reliable rides with our experienced drivers. We ensure timely and comfortable transportation for all your needs."
  },
  {
    icon: FaPhone,
    title: "24/7 Customer Support",
    description: "Our customer support team is available around the clock to assist with any questions or concerns you may have."
  },
  {
    icon: FaDollarSign,
    title: "Affordable Pricing",
    description: "We offer competitive pricing to ensure you get the best value for your money without compromising on quality."
  },
  {
    icon: FaMapMarkerAlt,
    title: "Wide Coverage",
    description: "We cover a vast range of locations to provide you with convenient transportation options across various regions."
  },
];

function Services() {
  return (
    <Box bg="rgba(0, 0, 0, 0.9)" color="white" p={8} minH="100vh">
      <Heading as="h2" size="xl" mb={8} textAlign="center">
        Our Services
      </Heading>
      <Flex direction={{ base: "column", md: "row" }} wrap="wrap" justify="center" spacing={8}>
        {servicesData.map((service, index) => (
          <Box key={index} bg="gray.800" p={6} borderRadius="md" boxShadow="md" maxW="300px" mx={4} mb={8}>
            <Flex align="center" mb={4}>
              <Icon as={service.icon} w={8} h={8} color="teal.400" mr={4} />
              <Text fontSize="2xl" fontWeight="bold">{service.title}</Text>
            </Flex>
            <Text fontSize="lg">{service.description}</Text>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
export default Services;