import React from "react";
import { Box, Flex, Link, Text, VStack, HStack, Image } from '@chakra-ui/react';

import { useNavigate } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

function Footer() {
  const navigate = useNavigate();
  return (
    <Box bg="black" color="white" p={10}>
      <Flex justify="space-between" wrap="wrap">
        <VStack align="flex-start" w={{ base: '100%', md: 'auto' }} mb={{ base: 6, md: 0 }}>
          <Image src={''} display={{ base: 'none', lg: 'block' }} w="5vw" />
        </VStack>

        <VStack align="flex-start" spacing={4} w={{ base: '34%', md: 'auto' }} mb={{ base: 6, md: 0 }}>
          <Text fontSize="lg" fontWeight="bold">Company</Text>
          <Link onClick={() => navigate("/AboutUs")}>About Us</Link>
          <Link onClick={() => navigate("/Privacypolicy")}>Privacy Policy</Link>
          <Link onClick={() => navigate("/Term")}>T&C </Link>
        
        </VStack>

       

        <VStack align="flex-start" spacing={4} w={{ base: '100%', md: 'auto' }} mb={{ base: 6, md: 0 }}>
          <Text fontSize="lg" fontWeight="bold">Support</Text>
          <Link onClick={() => navigate("/registration")} color="lightblue">support@Rido.in</Link>
        </VStack>

        <VStack align="flex-start" spacing={4} w={{ base: '100%', md: 'auto' }} mb={{ base: 6, md: 0 }}>
          <Text fontSize="lg" fontWeight="bold">Follow Us:</Text>
          <HStack spacing={4}>
            <Link>
              <FaFacebookF boxSize={{ base: '24px', md: '32px', lg: '40px' }} />
            </Link>
            <Link>
              <FaTwitter boxSize={{ base: '24px', md: '32px', lg: '40px' }} />
            </Link>
            <Link>
              <FaLinkedin boxSize={{ base: '24px', md: '32px', lg: '40px' }} />
            </Link>
            <Link>
              <FaInstagram boxSize={{ base: '24px', md: '32px', lg: '40px' }} />
            </Link>
          </HStack>
        </VStack>

        <HStack spacing={4} w={{ base: '100%', md: 'auto' }}   mt={'-6%'}>
          <Link>
            <Image
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Get it on Google Play"
              height="40px"
            />
          </Link>
          <Link>
            <Image
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="Download on the App Store"
              height="40px"
            />
          </Link>
        </HStack>
      </Flex>
    </Box>
  );
}

export default Footer;
